:root {
  --theme-color: #ffd000;
  --main-color: #0cc77f;
  --body-color: #3f7128;
  --content-color: #f0f2f1;
  --text-color: #ffffff;
  --bs-primary-rgb: rgba(0, 0, 0, 0.66);
  --text-light-color: #2a2a2a;
  --bg-color: #3f7128;
  --menu-color: #3f7128;
  --bg-light-color: #ffffff;
  --menu-light-color: #ffffff;
  --text-light-color: #2a2a2a;
}

*,
:after,
:before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:after,
:before {
  --tw-content: "";
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

body {
  margin: 0;
  line-height: inherit;
  background-color: var(--body-color);
}

#root {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb {
  opacity: 0;
}

::-webkit-scrollbar-track {
  background: none;
}

*,
:after,
:before {
  box-sizing: border-box;
}

*,
:after,
:before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.home-top-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  padding: 15px;
  background: var(--theme-color);
  margin: 0;

  .bg_header_name {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 19px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 60%;
    text-transform: uppercase;
  }

  .logo-div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .logo-img {
    width: 115px;
    object-fit: contain;
  }

  .btn_back {
    position: absolute;
    z-index: 9;
    left: 15px;
    top: 15px;
    cursor: pointer;
  }

  .btn_save {
    position: absolute;
    z-index: 9;
    right: 15px;
    top: 15px;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
    padding: 4px 11px;
  }

  .right {
    position: absolute;
    z-index: 9;
    right: 15px;
    top: 13px;
    font-size: 18px;
    color: #ffffff;
  }

  svg {
    color: #ffffff;
    font-size: 24px;
  }
}

.mobile-layout {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  background: #000000;
  max-width: initial;
}

.desktop-layout {
  position: relative;
  max-width: initial;
  margin: 0 auto;
}

.mobile-content {
  position: relative;
  z-index: 99;
}

.footer-mobile-top {
  position: fixed;
  z-index: 999;
  left: 50%;
  width: 100%;
  max-width: 540px;
  bottom: 47px;
  display: flex;
  justify-content: space-between;
  padding: 5px 30px;
  transform: translateX(-50%);
  border-top: 1px solid rgba(184, 184, 184, 0.53);
  color: #7f72a8;
  font-size: 15px;
  background: #ffffff;

  .data__balance {
    color: var(--theme-color);
  }
}

.footer-mobile {
  position: fixed;
  z-index: 999;
  background: #ffffff;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 540px;
  height: 47px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .footer_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    color: rgb(146, 146, 146);
    text-transform: uppercase;
    min-width: 92px;

    &.main-img {
      top: -0.26667rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .img-mark {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 73px;
        filter: grayscale(0) opacity(1);
      }
    }

    &.active {
      img {
        filter: grayscale(0) opacity(1);
      }

      svg {
        color: var(--theme-color);
      }
    }

    svg {
      font-size: 16px;
      margin-bottom: 4px;
      color: #cccccc;
    }

    h5 {
      font-size: 13px;
      font-weight: 600;
      text-align: center;
    }

    img {
      filter: grayscale(0.7) opacity(0.6);
      width: 37px;
      height: auto;
    }
  }
}

.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
  }

  .loading-img {
    width: 120px;
  }
}

.btn {
  box-shadow: none;
  border: none;
  outline: 0;
  text-align: center;
  background-color: var(--theme-color);
  color: #ffffff;
  width: 100%;
  padding: 6px 11px;
  font-size: 14px;
  min-height: 33px;
  border-radius: 8px;

  &:hover,
  &:focus {
    box-shadow: none;
    border: none;
    outline: 0;
    opacity: 0.8;
  }
}

.btn-group {
  display: flex;
  justify-content: center;
}

.btn-more {
  margin: 0 auto;
  margin-top: 15px;
  font-size: 17px;
  font-weight: 600;
}

.form-action {
  background-color: #ffffff;
  padding: 0 20px;

  .form-item {
    padding: 15px 0;

    .form_label {
      flex: none;
      box-sizing: border-box;
      text-align: left;
      word-wrap: break-word;
      margin-right: 15px;
      font-size: 18px;
    }

    .form_field {
      width: 100%;
    }

    &.error {
      .field_control {
        border: 1px solid #f55f5f;
      }

      .error_t {
        display: block;
        color: #f55f5f;
        margin-top: 5px;
      }
    }
  }

  label {
    display: flex;
    align-items: center;

    span {
      color: #363636;
      font-size: 18px;
      margin-left: 10px;
    }
  }
}

.form-horizontal {
  .form-item {
    display: flex;
  }
}

.field_control {
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 4px 10px;
  color: #363636;
  line-height: inherit;
  text-align: left;
  background-color: #eaeff2;
  height: 36px;
  border: 0;
  outline: 0;
  resize: none;
  font-size: 14px;
  border-radius: 7px;
}

.control-number {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 4px 10px;
  color: #363636;
  line-height: inherit;
  text-align: left;
  background-color: #eaeff2;
  border: 0;
  outline: 0;
  resize: none;
  font-size: 14px;
  border-radius: 7px;
  height: 36px;
}

.field_select {
  display: block;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  height: 36px;
  padding: 4px 10px;
  color: #363636;
  line-height: inherit;
  text-align: left;
  background-color: #eaeff2;
  border: 0;
  outline: 0;
  resize: none;
  font-size: 14px;
  border-radius: 7px;
}

.control-wrapper {
  display: flex;
  align-items: center;
  background-color: #eaeff2;

  svg {
    color: var(--theme-color);
    font-size: 18px;
    margin-right: 6px;
  }
}

.radio_control {
  width: 20px;
  height: 20px;
}

.bank-img {
  width: 100%;
  margin-top: 5%;
}

.text-red {
  color: #eb3f21 !important;
}

.modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  font-family: Roboto;
  font-size: 18px;

  .mask {
    position: absolute;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.603);
  }

  &.modal-full {
    .modal-content {
      top: 0;
      width: 100%;
      height: 100vh;
    }

    .content {
      padding: 0 20px;

      p {
        text-align: left;

        b {
          color: #000;
        }
      }

      ul {
        padding-left: 15px;

        li {
          color: #000;
          font-size: 16px;
          font-weight: 600;
          line-height: 26px;
          list-style: disc;
          margin-bottom: 10px;
        }
      }
    }
  }

  &.modal-bet-game {
    .modal-header {
      background: transparent;

      .btn-close {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        line-height: 25px;
        background: transparent;
        color: #000000;
        position: absolute;
        right: 10px;
        font-size: 22px;
        font-weight: 600;
      }
    }

    .modal-content {
      padding: 15px;

      .classic-bet-now {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;

        p {
          text-align: center;
        }
      }
    }

    .table-info {
      background-color: #f8f8fb;
      padding: 0 4px;
    }

    h2 {
      font-size: 30px;
      font-weight: 900;
      text-align: center;
      margin: 15px 0;
    }
  }

  &.modal-bet {
    &.modal-full {
      .modal-content {
        width: 100%;
        height: 100%;
        max-width: 100%;
        top: 0;
        left: 0;
        transform: none;
      }

      .modal-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }

    .modal-content {
      background-color: var(--bg-color);
      border-radius: 10px;
      overflow: hidden;
    }

    .modal-header {
      background-color: var(--bg-color);
      padding: 10px 15px;
      border-bottom: 1px solid #e6e6e6;

      h5 {
        text-align: center;
        font-size: 16px;
        color: #fff;
        width: 100%;
      }

      .close {
        color: #ffffff;
        font-size: 25px;
      }

      .btn-close {
        background: transparent;
        color: #ffffff;
        font-size: 25px;
        font-weight: 600;
        position: absolute;
        right: 15px;
        top: 5px;
      }
    }

    .modal-body {
      padding: 10px;

      p {
        font-size: 12px;
        color: #363d47;
      }
    }

    .signin-foote {
      color: #e8af2e;
      font-size: 18px;
      margin: 10px auto;
      display: block;
      text-decoration: none;
      text-align: center;
    }

    .btn-cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #46802b;
      color: #ffffff;
      height: 35px;
      font-size: 16px;
      font-weight: 500;
      padding: 25px 15px;
      width: 100%;
    }

    .info-table {
      padding: 10px 4px;
      border-radius: 4px;
      display: flex;

      p {
        color: #718ba7;
        margin-bottom: 2px;
      }

      .item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2px 0;
        flex: 1 1 auto;

        span {
          font-size: 13px;
          color: #ffffff;
          text-align: center;

          b {
            font-size: 16px;
          }

          &:first-child {
            color: #c4c4c4;
          }
        }
      }

      .input-number {
        height: 40px;
        border: none;
        outline: 0;
        width: 100%;
        color: #003874;
        border-radius: 6px;
        font-size: 16px;
        background-color: #e2e7ec;
      }
    }

    .info-table-flex {
      width: 100%;
      background: #123800;
      color: #fff;

      .item {
        border: none;
        font-size: 12px;
        padding: 0px;
        height: 51px;
        line-height: 51px;
        margin-bottom: 1px;
        color: rgb(200, 200, 200);
        background-color: #0f3000;
        border-bottom: 1px solid rgb(38, 40, 52) !important;

        .label {
          display: inline-block;
          padding: 7px 10px 7px 0px;
          max-width: 220px;
          width: 42%;
          color: rgb(200, 200, 200);
          font-size: 14px;
          padding-left: 25px;
          padding-top: 0px;
          padding-bottom: 0px;
          line-height: 15px;
        }

        .content {
          color: rgb(200, 200, 200);
          font-size: 14px;
        }
      }
    }

    .list-bet {
      margin-top: 5px;
      overflow: hidden;
      overflow-x: visible;
      white-space: nowrap;
      width: 100%;
      gap: 10px;

      .item {
        height: 110px;
        width: 130px;
        border-radius: 5px;
        overflow: hidden;
        display: inline-block;
        margin-right: 15px;
        background-image: url("../img/clock.png");
        background-size: contain;
        background-position: right top;
        background-repeat: no-repeat;
        background-color: #2c2d31;

        .period-widget-header {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 2px;

          h6 {
            color: #fff;
            font-weight: 400;
          }

          h4 {
            color: #fdbe19;
            font-size: 30px;
            display: flex;
            align-items: center;
            gap: 5px;
            font-weight: 900;

            small {
              font-weight: 400;
              font-size: 13px;
            }
          }
        }

        &.active {
          background-image: url("../img/sele.png");
          border: 1px solid #fdbe19;
        }

        p {
          width: 100%;
          height: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #46802b;
          color: #ffffff;
          font-size: 14px;
          margin-bottom: 2px;
        }
      }
    }

    .btn-confirm {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fcac00;
      color: #2e2e2e;
      height: 35px;
      font-size: 16px;
      font-weight: 500;
      padding: 12px 15px;
      width: 90%;
      margin: 15px auto 10px;
      border-radius: 10px;
    }

    .item-row {
      display: flex;
      justify-content: space-between;
      padding: 2px 0;

      span {
        font-size: 12px;
        color: #505966;
      }
    }
  }

  .text-black {
    color: #292929 !important;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;

    .btn-close {
      background: transparent;
      padding: 0;
      font-size: 22px;
    }
  }

  .modal-body {
    padding: 10px 15px;
  }

  .modal-content {
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 0 0 15px 15px;
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -40%);
    width: 100%;
    max-width: 36rem;
  }

  .modal-bottom {
    left: 0;
    bottom: 0;
    top: initial;
    width: 100%;
    transform: translateX(0);
    border-radius: 15px 15px 0 0;
  }

  .submit {
    display: flex;
    width: 100%;

    >button {
      flex: 1;
    }
  }

  .btn-close {
    background-color: #363636;
  }
}

.collapses {
  .collapse-item {
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 18px;

    .collapse-title {
      padding: 15px 20px;
      border-bottom: 1px solid #e4e4e4;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--theme-color);

      .van-icon {
        font-size: 20px;
        color: #979797;
      }
    }

    .collapse-wrapper {
      padding: 15px 20px;
      transition: height 0.2s ease-in-out;
      will-change: height;

      .collapse-body {
        padding-left: 20px;
        color: var(--theme-color);

        p {
          color: var(--theme-color);
        }
      }
    }
  }
}

.nodata {
  .noMSG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #969799;

    img {
      max-width: 195px;
      width: 100%;
    }
  }
}

.line {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
}

.remix-sidebar {
  z-index: 999;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: initial;
  overflow: hidden;
  overflow-y: auto;

  .menu-header {
    padding: 60px 20px 20px;
    background-color: #013f83;
    position: relative;

    .btn-close {
      position: absolute;
      z-index: 1;
      right: 20px;
      top: 30px;
      font-size: 32px;
      color: #ffffff;
    }

    h5 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    p {
      font-size: 16px;
    }
  }

  .menu-list {
    background-color: var(--bg-color);

    .button-logout {
      color: #fff;
      background-color: #007aff;
      font-size: 14px;
      text-align: center;
      width: 80%;
      padding: 8px 15px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }

    ul {
      li {

        a,
        .menu-item {
          display: flex;
          justify-content: space-between;
          padding: 15px 20px;
          color: #e0e0e0;
          border-bottom: 1px solid #003874;

          &.diver {
            border-bottom: 2px solid #004b9b;
          }

          .left {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            img {
              filter: brightness(0) invert(1);
              width: 24px;
              height: 24px;
            }
          }

          .right {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }
        }
      }
    }
  }
}

.mask {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #36363669;
}

@media screen and (max-width: 450px) {
  .footer-mobile .footer_item {
    min-width: 85px;

    h5 {
      font-size: 12px;
    }
  }

  .modal .modal-content {
    max-width: 90%;
  }
}

#highcharts iframe {
  height: calc(100vh - 160px);
  overflow: hidden;
}

.theme-color {
  color: var(--theme-color) !important;
}

.update-info-container,
.information-container,
.setting-container {
  min-height: 82vh;
  background-color: #ffffff;
}

.text-default {
  color: #fff;
}

.slide-question {
  padding: 10px;

  .item {
    display: flex;
    align-items: center;
    color: #fff;

    .info {
      margin-left: 10px;
    }

    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 15px;
    }
  }
}