.sbui-btn-container {
  display: inline-flex;
  font-weight: 500;
}

.sbui-btn {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
}

.sbui-btn > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-btn {
  border: 1px solid transparent;
  text-align: center;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, -webkit-transform, -webkit-filter,
    -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  border-radius: 0.25rem;
  font-family: inherit;
  font-weight: inherit;
}

.sbui-btn,
.sbui-btn:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.sbui-btn-container--shadow {
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.sbui-btn--w-full {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.sbui-btn:disabled {
  cursor: not-allowed;
  opacity: 0.75;
  pointer-events: none;
  background-color: #091944;
}

.sbui-btn--tiny {
  padding: 0.375rem 0.625rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

.sbui-btn--small {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1rem;
}

.sbui-btn--medium {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.sbui-btn--large {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.sbui-btn--xlarge {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.sbui-btn-primary {
  --tw-bg-opacity: 1;
  background-color: var(--theme-color);
}

.sbui-btn-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(101, 217, 165, var(--tw-bg-opacity));
}

.sbui-btn-primary {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.dark .sbui-btn-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(44, 156, 106, var(--tw-bg-opacity));
}

.dark .sbui-btn-primary {
  text-shadow: 0 0 4px rgba(38, 111, 78, 50%);
}

.sbui-btn-default {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: rgba(68, 68, 68, var(--tw-text-opacity));
}

.sbui-btn-default,
.sbui-btn-default:hover {
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.sbui-btn-default:hover {
  color: rgba(42, 42, 42, var(--tw-text-opacity));
}

.dark .sbui-btn-default,
.sbui-btn-default:hover {
  --tw-text-opacity: 1;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}

.dark .sbui-btn-default {
  border-color: rgba(42, 42, 42, var(--tw-border-opacity));
  background-color: rgba(42, 42, 42, var(--tw-bg-opacity));
  color: rgba(224, 224, 224, var(--tw-text-opacity));
}

.dark .sbui-btn-default:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 31, 31, var(--tw-bg-opacity));
}

.dark .sbui-btn-default {
  text-shadow: 0 0 4px rgba(31, 31, 31, 50%);
}

.sbui-btn-secondary {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(42, 42, 42, var(--tw-text-opacity));
}

.sbui-btn-secondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 187, 187, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(31, 31, 31, var(--tw-text-opacity));
}

.dark .sbui-btn-secondary {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 68, 68, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}

.dark .sbui-btn-secondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(42, 42, 42, var(--tw-bg-opacity));
}

.dark .sbui-btn-secondary {
  text-shadow: 0 0 4px rgba(42, 42, 42, 50%);
}

.sbui-btn-outline {
  --tw-border-opacity: 1;
  border: 1px solid rgba(224, 224, 224, var(--tw-border-opacity));
}

.sbui-btn-outline:hover {
  --tw-border-opacity: 1;
  border-color: rgba(42, 42, 42, var(--tw-border-opacity));
}

.dark .sbui-btn-outline {
  --tw-border-opacity: 1;
  border-color: rgba(102, 102, 102, var(--tw-border-opacity));
}

.dark .sbui-btn-outline:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.sbui-btn-outline {
  background-color: transparent;
}

.sbui-btn-outline:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.sbui-btn-outline {
  --tw-text-opacity: 1;
  color: rgba(68, 68, 68, var(--tw-text-opacity));
}

.sbui-btn-outline:hover {
  --tw-text-opacity: 1;
  color: rgba(42, 42, 42, var(--tw-text-opacity));
}

.dark .sbui-btn-outline {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.dark .sbui-btn-outline:hover {
  --tw-text-opacity: 1;
  color: rgba(42, 42, 42, var(--tw-text-opacity));
}

.sbui-btn-dashed {
  --tw-border-opacity: 1;
  border: 1px dashed rgba(224, 224, 224, var(--tw-border-opacity));
}

.sbui-btn-dashed:hover {
  --tw-border-opacity: 1;
  border-color: rgba(42, 42, 42, var(--tw-border-opacity));
}

.sbui-btn-dashed {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(68, 68, 68, var(--tw-text-opacity));
}

.sbui-btn-dashed:hover {
  --tw-text-opacity: 1;
  color: rgba(42, 42, 42, var(--tw-text-opacity));
}

.dark .sbui-btn-dashed {
  --tw-border-opacity: 1;
  border-color: rgba(102, 102, 102, var(--tw-border-opacity));
}

.dark .sbui-btn-dashed:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.dark .sbui-btn-dashed,
.dark .sbui-btn-dashed:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.sbui-btn-link {
  background-color: transparent;
}

.sbui-btn-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(44, 156, 106, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.25;
}

.sbui-btn-link {
  --tw-text-opacity: 1;
  color: rgba(36, 180, 126, var(--tw-text-opacity));
  box-shadow: none;
}

.sbui-btn-text {
  background-color: transparent;
}

.sbui-btn-text:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.25;
}

.sbui-btn-text {
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}

.dark .sbui-btn-text:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(42, 42, 42, var(--tw-bg-opacity));
}

.dark .sbui-btn-text {
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}

.sbui-btn-text {
  box-shadow: none;
}

.dark .sbui-btn--danger,
.sbui-btn--danger {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.dark .sbui-btn--danger {
  text-shadow: 0 0 4px rgba(229, 62, 62, 50%);
}

.sbui-btn-primary.sbui-btn--danger {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.sbui-btn-primary.sbui-btn--danger:hover {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.sbui-btn-dashed.sbui-btn--danger:hover,
.sbui-btn-default.sbui-btn--danger:hover,
.sbui-btn-link.sbui-btn--danger:hover,
.sbui-btn-outline.sbui-btn--danger:hover,
.sbui-btn-secondary.sbui-btn--danger:hover,
.sbui-btn-text.sbui-btn--danger:hover {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.sbui-btn--anim--spin {
  stroke: currentColor;
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.sbui-btn-loader--center {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: auto 0;
  width: inherit;
  height: inherit;
}

.sbui-btn--text-fade-out span {
  opacity: 0;
}

.sbui-btn--text-align-left {
  justify-content: flex-start;
}

.sbui-btn--text-align-right {
  justify-content: flex-end;
}

.sbui-btn--text-align-center {
  justify-content: center;
}

.sbui-space {
  display: flex;
}

.sbui-space-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sbui-space-col {
  display: flex;
  flex-direction: column;
}

.sbui-space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.sbui-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.sbui-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.sbui-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.sbui-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.sbui-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.sbui-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.sbui-space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}

.sbui-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.sbui-space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.25rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
}

.sbui-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.sbui-space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.75rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
}

.sbui-space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.sbui-space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
}

.sbui-space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}

.sbui-space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}

.sbui-space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6rem * var(--tw-space-y-reverse));
}

.sbui-space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(7rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(7rem * var(--tw-space-y-reverse));
}

.sbui-space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8rem * var(--tw-space-y-reverse));
}

.sbui-space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(9rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(9rem * var(--tw-space-y-reverse));
}

.sbui-space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(10rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10rem * var(--tw-space-y-reverse));
}

.sbui-space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(11rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(11rem * var(--tw-space-y-reverse));
}

.sbui-space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12rem * var(--tw-space-y-reverse));
}

.sbui-space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(13rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(13rem * var(--tw-space-y-reverse));
}

.sbui-space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(14rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(14rem * var(--tw-space-y-reverse));
}

.sbui-space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(15rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(15rem * var(--tw-space-y-reverse));
}

.sbui-space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(16rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(16rem * var(--tw-space-y-reverse));
}

.sbui-space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(18rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(18rem * var(--tw-space-y-reverse));
}

.sbui-space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(20rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(20rem * var(--tw-space-y-reverse));
}

.sbui-space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(24rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(24rem * var(--tw-space-y-reverse));
}

.sbui-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1px * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1px * var(--tw-space-y-reverse));
}

.sbui-space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(1.75rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(2.25rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(2.75rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(3.5rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(5rem * var(--tw-space-x-reverse));
  margin-left: calc(5rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6rem * var(--tw-space-x-reverse));
  margin-left: calc(6rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(7rem * var(--tw-space-x-reverse));
  margin-left: calc(7rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8rem * var(--tw-space-x-reverse));
  margin-left: calc(8rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(9rem * var(--tw-space-x-reverse));
  margin-left: calc(9rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(10rem * var(--tw-space-x-reverse));
  margin-left: calc(10rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(11rem * var(--tw-space-x-reverse));
  margin-left: calc(11rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12rem * var(--tw-space-x-reverse));
  margin-left: calc(12rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(13rem * var(--tw-space-x-reverse));
  margin-left: calc(13rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(14rem * var(--tw-space-x-reverse));
  margin-left: calc(14rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(15rem * var(--tw-space-x-reverse));
  margin-left: calc(15rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(16rem * var(--tw-space-x-reverse));
  margin-left: calc(16rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(18rem * var(--tw-space-x-reverse));
  margin-left: calc(18rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(20rem * var(--tw-space-x-reverse));
  margin-left: calc(20rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(24rem * var(--tw-space-x-reverse));
  margin-left: calc(24rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1px * var(--tw-space-x-reverse));
  margin-left: calc(1px * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.25rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.75rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.25rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.75rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.25rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.75rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3.5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-4rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-5rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-6rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-7rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-8rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-9rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-10rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-11rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-12rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-13rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-14rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-15rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-16rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-18rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-20rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-24rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
}

.sbui-minus-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1px * var(--tw-space-y-reverse));
}

.sbui-minus-space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.25rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.5rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.75rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1rem * var(--tw-space-x-reverse));
  margin-left: calc(-1rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.25rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.5rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.75rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2rem * var(--tw-space-x-reverse));
  margin-left: calc(-2rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.25rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.5rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.75rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3rem * var(--tw-space-x-reverse));
  margin-left: calc(-3rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-3.5rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-4rem * var(--tw-space-x-reverse));
  margin-left: calc(-4rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-5rem * var(--tw-space-x-reverse));
  margin-left: calc(-5rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-6rem * var(--tw-space-x-reverse));
  margin-left: calc(-6rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-7rem * var(--tw-space-x-reverse));
  margin-left: calc(-7rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-8rem * var(--tw-space-x-reverse));
  margin-left: calc(-8rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-9rem * var(--tw-space-x-reverse));
  margin-left: calc(-9rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-10rem * var(--tw-space-x-reverse));
  margin-left: calc(-10rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-11rem * var(--tw-space-x-reverse));
  margin-left: calc(-11rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-12rem * var(--tw-space-x-reverse));
  margin-left: calc(-12rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-13rem * var(--tw-space-x-reverse));
  margin-left: calc(-13rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-14rem * var(--tw-space-x-reverse));
  margin-left: calc(-14rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-15rem * var(--tw-space-x-reverse));
  margin-left: calc(-15rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-16rem * var(--tw-space-x-reverse));
  margin-left: calc(-16rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-18rem * var(--tw-space-x-reverse));
  margin-left: calc(-18rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-20rem * var(--tw-space-x-reverse));
  margin-left: calc(-20rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-minus-space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-24rem * var(--tw-space-x-reverse));
  margin-left: calc(-24rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-card {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.dark .sbui-card {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 31, 31, var(--tw-bg-opacity));
}

.sbui-card--hoverable {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x))
    translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.sbui-card--hoverable:hover {
  --tw-translate-y: -0.25rem;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.sbui-card--hoverable {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, -webkit-transform, -webkit-filter,
    -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.dark .sbui-card {
  border: 1px solid #2a2a2a;
}

.sbui-card-head {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #e0e0e0;
}

.dark .sbui-card-head {
  border-bottom: 1px solid #2a2a2a;
}

.sbui-card-content {
  padding: 2rem;
}

.sbui-input-container {
  position: relative;
  &.control-flex {
    display: flex;
    align-items: center;
    gap: 10px;
    .sbui-select {
      flex: 1;
      width: 40%;
      min-width: none;
      background-color: #f7f7f9;
      border: none;
    }
    img {
      height: 28px;
    }
  }
}

.sbui-input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  --tw-border-opacity: 1;
  border: 1px solid rgba(209, 213, 219, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.sbui-input:focus {
  --tw-border-opacity: 1;
  border-color: rgba(159, 231, 199, var(--tw-border-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 231, 199, var(--tw-ring-opacity));
}

.dark .sbui-input {
  --tw-border-opacity: 1;
  border-color: #e0e0e0;
  background-color: #ffff;
  --tw-text-opacity: 1;
  color: #3b3b3b;
}

.dark .sbui-input:focus {
  --tw-border-opacity: 1;
  border-color: rgba(159, 231, 199, var(--tw-border-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 231, 199, var(--tw-ring-opacity));
}

.sbui-input {
  font-family: inherit;
  transition: box-shadow 0.3s ease-in-out;
  background: #f7f6fb;
  border: none;
}

.sbui-input:focus {
  box-shadow: 0 0 0 2px rgba(62, 207, 142, 0.1);
}

.dark .sbui-input--error,
.sbui-input--error {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.sbui-input--borderless {
  border-color: transparent;
  --tw-shadow: 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.sbui-input-actions-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 0.25rem;
  padding-left: 0.75rem;
}

.sbui-input--tiny {
  padding: 0.375rem 0.625rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

.sbui-input--small {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1rem;
}

.sbui-input--medium {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.sbui-input--large {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.sbui-input--xlarge {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.sbui-input--with-icon {
  padding-left: 2.5rem;
}

.sbui-formlayout {
  display: grid;
  gap: 0.5rem;
}

.sbui-formlayout--flex {
  display: flex;
  justify-content: space-between;
}

.sbui-formlayout--flex-right div:last-child {
  text-align: right;
}

.sbui-formlayout--flex-left div:first-child {
  order: 2;
}

.sbui-formlayout--flex-left div:last-child {
  order: 1;
}

.sbui-formlayout--non-responsive {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 1rem;
}

@media (min-width: 768px) {
  .sbui-formlayout--responsive {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 1rem;
  }
}

.sbui-formlayout__label-container-horizontal {
  grid-column: span 12 / span 12;
  justify-content: space-between;
}

.sbui-formlayout__label-container-vertical {
  grid-column: span 4 / span 4;
}

.sbui-formlayout__content-container-horizontal {
  grid-column: span 12 / span 12;
}

.sbui-formlayout__content-container-vertical {
  grid-column: span 8 / span 8;
}

.sbui-formlayout__content-container-vertical--align-right {
  text-align: right;
}

.sbui-formlayout__label {
  display: block;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.dark .sbui-formlayout__label {
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity));
}

.sbui-formlayout__label-opt,
.sbui-formlayout__label .sbui-formlayout__label-after,
.sbui-formlayout__label .sbui-formlayout__label-before {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}

.dark .sbui-formlayout__label-opt,
.dark .sbui-formlayout__label .sbui-formlayout__label-after,
.dark .sbui-formlayout__label .sbui-formlayout__label-before {
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}

.sbui-formlayout__error {
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.sbui-formlayout__description,
.sbui-formlayout__error {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
}

.sbui-formlayout__description {
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}

.dark .sbui-formlayout__description {
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}

.sbui-formlayout--tiny .sbui-formlayout__description,
.sbui-formlayout--tiny .sbui-formlayout__error,
.sbui-formlayout--tiny .sbui-formlayout__label,
.sbui-formlayout--tiny .sbui-formlayout__label-opt {
  font-size: 0.75rem;
  line-height: 1rem;
}

.sbui-formlayout--small .sbui-formlayout__description,
.sbui-formlayout--small .sbui-formlayout__error,
.sbui-formlayout--small .sbui-formlayout__label,
.sbui-formlayout--small .sbui-formlayout__label-opt {
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1rem;
}

.sbui-formlayout--medium .sbui-formlayout__description,
.sbui-formlayout--medium .sbui-formlayout__error,
.sbui-formlayout--medium .sbui-formlayout__label,
.sbui-formlayout--medium .sbui-formlayout__label-opt {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 4px;
}

.sbui-formlayout--large .sbui-formlayout__description,
.sbui-formlayout--large .sbui-formlayout__error,
.sbui-formlayout--large .sbui-formlayout__label,
.sbui-formlayout--large .sbui-formlayout__label-opt,
.sbui-formlayout--xlarge .sbui-formlayout__description,
.sbui-formlayout--xlarge .sbui-formlayout__error,
.sbui-formlayout--xlarge .sbui-formlayout__label,
.sbui-formlayout--xlarge .sbui-formlayout__label-opt {
  font-size: 1rem;
  line-height: 1.5rem;
}

.sbui-select-container {
  position: relative;
}

.sbui-select {
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 0.5rem 2.5rem 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  --tw-border-opacity: 1;
  border: 1px solid rgba(209, 213, 219, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.sbui-select:focus {
  --tw-border-opacity: 1;
  border-color: rgba(159, 231, 199, var(--tw-border-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 231, 199, var(--tw-ring-opacity));
}

.dark .sbui-select {
  --tw-border-opacity: 1;
  border-color: rgba(68, 68, 68, var(--tw-border-opacity));
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity));
}

.dark .sbui-select:focus {
  --tw-border-opacity: 1;
  border-color: rgba(159, 231, 199, var(--tw-border-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 231, 199, var(--tw-ring-opacity));
}

.sbui-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-overflow: "";
  transition: box-shadow 0.3s ease-in-out;
  background-image: none;
  border: none;
  background: #f7f6fb;
}

.sbui-select option {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(24, 24, 24, var(--tw-text-opacity));
}

.sbui-select:focus {
  box-shadow: 0 0 0 2px rgba(62, 207, 142, 0.1);
}

.dark .sbui-select--error,
.sbui-select--error {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.sbui-select--borderless {
  border-color: transparent;
  --tw-shadow: 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.sbui-select--tiny {
  padding: 0.375rem 0.625rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

.sbui-select--small {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1rem;
}

.sbui-select--medium {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.sbui-select--large {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.sbui-select--xlarge {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.sbui-select-actions-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin-right: 1.25rem;
  display: flex;
  align-items: center;
  padding-right: 0.25rem;
  padding-left: 0.75rem;
}

.sbui-select--with-icon {
  padding-left: 2.5rem;
}

.sbui-select-chevron-container {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
}

.sbui-select-chevron {
  height: 1.25rem;
  width: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}

.sbui-modal-overlay--enter {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.sbui-modal-overlay--enterFrom {
  opacity: 0;
}

.sbui-modal-overlay--enterTo {
  opacity: 1;
}

.sbui-modal-overlay--leave {
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.sbui-modal-overlay--leaveFrom {
  opacity: 1;
}

.sbui-modal-overlay--leaveTo {
  opacity: 0;
}

.sbui-modal--enter {
  transition-delay: 0.15s;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.sbui-modal--enterFrom {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x))
    translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: 1rem;
  opacity: 0;
}

@media (min-width: 640px) {
  .sbui-modal--enterFrom {
    --tw-translate-y: 0px;
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
}

.sbui-modal--enterTo {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x))
    translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: 0px;
  opacity: 1;
}

@media (min-width: 640px) {
  .sbui-modal--enterTo {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
}

.sbui-modal--leave {
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.sbui-modal--leaveFrom {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x))
    translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: 0px;
  opacity: 1;
}

@media (min-width: 640px) {
  .sbui-modal--leaveFrom {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
}

.sbui-modal--leaveTo {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x))
    translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: 1rem;
  opacity: 0;
}

@media (min-width: 640px) {
  .sbui-modal--leaveTo {
    --tw-translate-y: 0px;
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
}

.sbui-modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
}

.sbui-modal-flex-container {
  display: flex;
  min-height: 100vh;
  align-items: flex-end;
  justify-content: center;
  padding: 1rem 1.5rem 5rem;
  text-align: center;
}

@media (min-width: 640px) {
  .sbui-modal-flex-container {
    display: block;
    padding: 0;
  }
}

.sbui-modal-overlay-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.sbui-modal-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  --tw-bg-opacity: 1;
  background-color: rgba(15, 15, 15, var(--tw-bg-opacity));
  opacity: 0.75;
}

.sbui-modal-div-trick {
  display: none;
}

@media (min-width: 640px) {
  .sbui-modal-div-trick {
    display: inline-block;
    height: 100vh;
    vertical-align: middle;
  }
}

.sbui-modal {
  display: inline-block;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x))
    translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  overflow: hidden;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.dark .sbui-modal {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 24, var(--tw-bg-opacity));
}

.sbui-modal {
  text-align: left;
  vertical-align: middle;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border: 1px solid rgba(102, 102, 102, var(--tw-border-opacity));
}

.dark .sbui-modal {
  --tw-border-opacity: 1;
  border-color: rgba(42, 42, 42, var(--tw-border-opacity));
}

.sbui-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (min-width: 640px) {
  .sbui-modal--tiny {
    width: 100%;
    max-width: 20rem;
    vertical-align: middle;
  }
}

@media (min-width: 640px) {
  .sbui-modal--small {
    width: 100%;
    max-width: 24rem;
    vertical-align: middle;
  }
}

@media (min-width: 640px) {
  .sbui-modal--medium {
    width: 100%;
    max-width: 32rem;
    vertical-align: middle;
  }
}

.sbui-modal--large {
  max-width: 36rem;
}

@media (min-width: 640px) {
  .sbui-modal--large {
    width: 100%;
    vertical-align: middle;
  }
}

.sbui-modal-content {
  padding: 1.25rem 1.5rem;
}

@media (min-width: 640px) {
  .sbui-modal-content {
    padding: 1.5rem;
  }
}

.sbui-modal-footer {
  justify-content: flex-end;
}

@media (min-width: 640px) {
  .sbui-modal-footer {
    display: flex;
    flex-direction: row;
  }
}

.sbui-modal-footer--with-bg {
  justify-content: flex-end;
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.dark .sbui-modal-footer--with-bg {
  --tw-bg-opacity: 1;
  background-color: rgba(42, 42, 42, var(--tw-bg-opacity));
}

.sbui-modal-footer--with-bg {
  padding: 0.75rem 1.5rem;
}

@media (min-width: 640px) {
  .sbui-modal-footer--with-bg {
    display: flex;
    flex-direction: row;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.sbui-modal-icon-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 3rem;
  width: 3rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}

@media (min-width: 640px) {
  .sbui-modal-icon-container {
    margin-left: 0;
    margin-right: 0;
    height: 2.5rem;
    width: 2.5rem;
  }
}

.sbui-modal-icon-container--danger {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.sbui-modal-icon-container--warning {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.sbui-modal-icon-container--success {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 231, 199, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
  --tw-text-opacity: 1;
  color: rgba(159, 231, 199, var(--tw-text-opacity));
}

.sbui-modal-close-container {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
}

.dark .sbui-modal-close-container button,
.sbui-modal-close-container button {
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}

.dark .sbui-modal-close-container button:hover,
.sbui-modal-close-container button:hover {
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}

.dark .dark .sbui-modal-close-container button,
.dark .sbui-modal-close-container button {
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}

.dark .sbui-modal-close-container button:hover,
.sbui-modal-close-container button:hover {
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
  background-color: transparent;
}

.sbui-sidepanel__trigger {
  border-style: none;
  background-color: transparent;
  padding: 0;
}

.sbui-sidepanel__trigger:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}

.sbui-listbox-container {
  position: relative;
}

.sbui-listbox {
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 0.5rem 2.5rem 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  --tw-border-opacity: 1;
  border: 1px solid rgba(187, 187, 187, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(31, 31, 31, var(--tw-text-opacity));
  cursor: default;
}

.sbui-listbox:focus {
  --tw-border-opacity: 1;
  border-color: rgba(101, 217, 165, var(--tw-border-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(101, 217, 165, var(--tw-ring-opacity));
}

.dark .sbui-listbox {
  --tw-border-opacity: 1;
  border: none;
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.dark .sbui-listbox:focus {
  --tw-border-opacity: 1;
  border-color: rgba(159, 231, 199, var(--tw-border-opacity));
}

.sbui-listbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  box-shadow: 0 0 0 2px hsla(0, 0%, 100%, 0);
  transition: box-shadow 0.3s ease-in-out;
  background-image: none;
}

.sbui-listbox:focus {
  box-shadow: 0 0 0 2px rgba(62, 207, 142, 0.1);
}

.dark .sbui-listbox--error,
.sbui-listbox--error {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.sbui-listbox--borderless {
  border-color: transparent;
  --tw-shadow: 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.sbui-listbox--tiny {
  padding: 0.375rem 0.625rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

.sbui-listbox--small {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1rem;
}

.sbui-listbox--medium {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.sbui-listbox--large {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.sbui-listbox--xlarge {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.sbui-listbox-actions-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin-right: 1.25rem;
  display: flex;
  align-items: center;
  padding-right: 0.25rem;
  padding-left: 0.75rem;
}

.sbui-listbox--with-icon {
  padding-left: 2.5rem;
}

.sbui-listbox-addonbefore {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.sbui-listbox-addonbefore > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-listbox-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sbui-listbox-chevron-container {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
}

.sbui-listbox-chevron {
  height: 1.25rem;
  width: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}

.sbui-listbox-option-container {
  position: absolute;
  z-index: 10;
  margin-top: 0.25rem;
  max-height: 15rem;
  width: 100%;
  list-style-type: none;
  overflow: auto;
  border-radius: 0.375rem;
  --tw-border-opacity: 1;
  border: 1px solid rgba(238, 238, 238, var(--tw-border-opacity));
}

.dark .sbui-listbox-option-container {
  --tw-border-opacity: 1;
  border-color: rgba(42, 42, 42, var(--tw-border-opacity));
}

.sbui-listbox-option-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.dark .sbui-listbox-option-container {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 24, var(--tw-bg-opacity));
}

.sbui-listbox-option-container {
  padding: 0.25rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.sbui-listbox-option-container:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.sbui-listbox-option-container {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.05;
}

@media (min-width: 640px) {
  .sbui-listbox-option-container {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.sbui-listbox-option {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.5rem 2.25rem 0.5rem 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(15, 15, 15, var(--tw-text-opacity));
}

.dark .sbui-listbox-option {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));

  &:not(.sbui-listbox-option--disabled):hover {
    --tw-bg-opacity: 1;
    background-color: rgba(101, 217, 165, var(--tw-bg-opacity));
    --tw-bg-opacity: 0.2;

    span {
      --tw-text-opacity: 1;
      color: rgba(101, 217, 165, var(--tw-text-opacity));
    }
  }
}

.sbui-listbox-option--active {
  --tw-bg-opacity: 1;
  background-color: rgba(101, 217, 165, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.dark .sbui-listbox-option--active,
.sbui-listbox-option--active {
  --tw-text-opacity: 1;
  color: rgba(101, 217, 165, var(--tw-text-opacity));
}

.sbui-listbox-option--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.sbui-listbox-option__inner {
  display: flex;
  align-items: center;
}

.sbui-listbox-option__inner > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-listbox-option__check {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 0.75rem;
}

.sbui-listbox-option__check--active {
  --tw-text-opacity: 1;
  color: rgba(101, 217, 165, var(--tw-text-opacity));
}

.sbui-listbox-option__check__icon {
  height: 1.25rem;
  width: 1.25rem;
}

.sbui-listbox-transition--leave {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, -webkit-transform, -webkit-filter,
    -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.sbui-listbox-transition--leave-from {
  opacity: 1;
}

.sbui-listbox-transition--leave-to {
  opacity: 0;
}

.sbui-loading {
  position: relative;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.sbui-loading--active .sbui-loading-content {
  opacity: 0.4;
  z-index: 9999;
}

.sbui-loading-content {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  transition-duration: 0.3s;
}

.sbui-loading-spinner {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  --tw-text-opacity: 1;
  color: rgba(36, 180, 126, var(--tw-text-opacity));
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.sbui-loading--active .loading-mask {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.sbui-dropdown-item {
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.dark .sbui-dropdown-item {
  --tw-text-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-text-opacity));
}

.sbui-dropdown-item {
  display: flex;
  align-items: center;
}

.sbui-dropdown-item > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-dropdown-item {
  padding: 0.375rem 1rem;
  cursor: pointer;
  border-style: none;
}

.sbui-dropdown-item:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}

.dark .sbui-dropdown-item:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 24, var(--tw-bg-opacity));
}

.sbui-dropdown-item {
  cursor: "default";
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sbui-dropdown-item svg {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.dark .sbui-dropdown-item svg {
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}

.sbui-dropdown-label {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.dark .sbui-dropdown-label {
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}

.sbui-dropdown-label {
  display: flex;
  align-items: center;
}

.sbui-dropdown-label > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-dropdown-label {
  padding: 0.375rem 1rem;
  font-size: 0.65rem;
}

.sbui-dropdown-misc {
  padding: 0.375rem 1rem;
}

.sbui-dropdown-item:focus-visible {
  border: none;
  outline: none;
}

.sbui-dropdown-input {
  display: flex;
  align-items: center;
}

.sbui-dropdown-input > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
}

.sbui-dropdown-input {
  padding-right: 1rem;
  padding-left: 2rem;
}

.sbui-dropdown-input__check {
  position: absolute;
  left: 0.5rem;
  display: flex;
  align-items: center;
}

.sbui-dropdown__content {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.dark .sbui-dropdown__content {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 31, 31, var(--tw-bg-opacity));
}

.sbui-dropdown__content {
  padding: 0;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(240, 242, 245, var(--tw-border-opacity));
}

.dark .sbui-dropdown__content {
  --tw-border-opacity: 1;
  border-color: rgba(42, 42, 42, var(--tw-border-opacity));
}

.sbui-dropdown__content {
  border-radius: 0.25rem;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  border-style: solid;
  border-width: 1px;
}

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  to {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  to {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0;
  }
}

.sbui-dropdown__content {
  -webkit-transform-origin: "var(--radix-dropdown-menu-content-transform-origin)";
  transform-origin: "var(--radix-dropdown-menu-content-transform-origin)";
}

.sbui-dropdown__content[data-state="open"] {
  -webkit-animation: fadeIn 50ms ease-out;
  animation: fadeIn 50ms ease-out;
}

.sbui-dropdown__content[data-state="closed"] {
  -webkit-animation: fadeOut 50ms ease-in;
  animation: fadeOut 50ms ease-in;
}

.sbui-dropdown__trigger {
  border-style: none;
  background-color: transparent;
  padding: 0;
  min-width: 120px;
  border: none;
  box-shadow: none;
}

.sbui-dropdown__trigger:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}

.sbui-dropdown__arrow {
  fill: currentColor;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.dark .sbui-dropdown__arrow {
  --tw-text-opacity: 1;
  color: rgba(31, 31, 31, var(--tw-text-opacity));
}

.sbui-dropdown__arrow {
  border-width: 1px 0 0;
  border-style: solid;
}

.sbui-dropdown-item-trigger {
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.dark .sbui-dropdown-item-trigger {
  --tw-text-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-text-opacity));
}

.sbui-dropdown-item-trigger {
  display: flex;
  align-items: center;
}

.sbui-dropdown-item-trigger > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
}

.sbui-dropdown-item-trigger {
  padding: 0.375rem 1rem;
  cursor: pointer;
  cursor: "default";
}

.sbui-dropdown-item-trigger svg {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.dark .sbui-dropdown-item-trigger svg {
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}

.sbui-dropdown-item-trigger:focus-visible {
  border: none;
  outline: none;
}

.sbui-dropdown__trigger-item[data-state="open"] {
  border-style: none;
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}

.dark .sbui-dropdown__trigger-item[data-state="open"] {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 24, var(--tw-bg-opacity));
}

.dialog-wallet {
  position: fixed;
  top: 64px;
  right: 80px;
  min-width: max-content;
  z-index: 9;

  .mask {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }

  .sbui-dropdown__content {
    position: relative;
    outline: none;
    z-index: 10;
    pointer-events: auto;
  }
}

.dialog-setting {
  position: absolute;
  top: 64px;
  right: 10px;
  min-width: max-content;

  .mask {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }

  .sbui-popover__content {
    position: relative;
    outline: none;
    z-index: 10;
    pointer-events: auto;
  }

  .sbui-dropdown__trigger {
    width: 120px;

    .sbui-listbox {
      padding: 2px 10px;
    }
  }
}

.sbui-badge {
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
  padding: 0.125rem 0.625rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgba(36, 180, 126, var(--tw-text-opacity));
}

.sbui-badge--large {
  border-radius: 9999px;
  padding: 0.125rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.sbui-badge-dot {
  margin-right: 0.375rem;
  margin-left: -0.125rem;
  height: 0.5rem;
  width: 0.5rem;
}

.sbui-badge--gray {
  background-color: rgba(42, 42, 42, var(--tw-bg-opacity));
  color: rgba(68, 68, 68, var(--tw-text-opacity));
}

.sbui-badge--gray,
.sbui-badge--red {
  --tw-bg-opacity: 1;
  --tw-bg-opacity: 0.1;
  --tw-text-opacity: 1;
}

.sbui-badge--red {
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.sbui-badge--yellow {
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.sbui-badge--green,
.sbui-badge--yellow {
  --tw-bg-opacity: 1;
  --tw-bg-opacity: 0.1;
  --tw-text-opacity: 1;
}

.sbui-badge--green {
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.sbui-badge--blue {
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.sbui-badge--blue,
.sbui-badge--indigo {
  --tw-bg-opacity: 1;
  --tw-bg-opacity: 0.1;
  --tw-text-opacity: 1;
}

.sbui-badge--indigo {
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.sbui-badge--purple {
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}

.sbui-badge--pink,
.sbui-badge--purple {
  --tw-bg-opacity: 1;
  --tw-bg-opacity: 0.1;
  --tw-text-opacity: 1;
}

.sbui-badge--pink {
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}

.sbui-divider {
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.sbui-divider-vertical {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity));
}

.dark .sbui-divider-vertical {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 68, 68, var(--tw-bg-opacity));
}

.sbui-divider-vertical {
  width: 1px;
  height: inherit;
}

.sbui-divider-vertical.sbui-divider--light {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity));
}

.dark .sbui-divider-vertical.sbui-divider--light {
  --tw-bg-opacity: 1;
  background-color: rgba(42, 42, 42, var(--tw-bg-opacity));
}

.sbui-divider--no-text {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
}

.dark .sbui-divider--no-text {
  --tw-border-opacity: 1;
  border-color: rgba(68, 68, 68, var(--tw-border-opacity));
}

.sbui-divider--no-text {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.sbui-divider > span {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}

.dark .sbui-divider > span {
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}

.sbui-divider > span {
  display: inline-block;
  padding: 0 1em;
}

.sbui-divider--left:before {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
}

.dark .sbui-divider--left:before {
  --tw-border-opacity: 1;
  border-color: rgba(68, 68, 68, var(--tw-border-opacity));
}

.sbui-divider--left:before {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  top: 50%;
  content: "";
  position: relative;
  display: inline-block;
  width: 5%;
}

.sbui-divider--left:after {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
}

.dark .sbui-divider--left:after {
  --tw-border-opacity: 1;
  border-color: rgba(68, 68, 68, var(--tw-border-opacity));
}

.sbui-divider--left:after {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  top: 50%;
  content: "";
  position: relative;
  display: inline-block;
  width: 95%;
}

.sbui-divider--right:before {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
}

.dark .sbui-divider--right:before {
  --tw-border-opacity: 1;
  border-color: rgba(68, 68, 68, var(--tw-border-opacity));
}

.sbui-divider--right:before {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  top: 50%;
  content: "";
  position: relative;
  display: inline-block;
  width: 95%;
}

.sbui-divider--right:after {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
}

.dark .sbui-divider--right:after {
  --tw-border-opacity: 1;
  border-color: rgba(68, 68, 68, var(--tw-border-opacity));
}

.sbui-divider--right:after {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  top: 50%;
  content: "";
  position: relative;
  display: inline-block;
  width: 5%;
}

.sbui-divider--center:after,
.sbui-divider--center:before {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
}

.dark .sbui-divider--center:after,
.dark .sbui-divider--center:before {
  --tw-border-opacity: 1;
  border-color: rgba(68, 68, 68, var(--tw-border-opacity));
}

.sbui-divider--center:after,
.sbui-divider--center:before {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  top: 50%;
  content: "";
  position: relative;
  display: inline-block;
  width: 50%;
}

.sbui-divider--light.sbui-divider--center:after,
.sbui-divider--light.sbui-divider--center:before,
.sbui-divider--light.sbui-divider--left:after,
.sbui-divider--light.sbui-divider--left:before,
.sbui-divider--light.sbui-divider--no-text,
.sbui-divider--light.sbui-divider--right:after,
.sbui-divider--light.sbui-divider--right:before {
  --tw-border-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-border-opacity));
}

.dark .sbui-divider--light.sbui-divider--center:after,
.dark .sbui-divider--light.sbui-divider--center:before,
.dark .sbui-divider--light.sbui-divider--left:after,
.dark .sbui-divider--light.sbui-divider--left:before,
.dark .sbui-divider--light.sbui-divider--no-text,
.dark .sbui-divider--light.sbui-divider--right:after,
.dark .sbui-divider--light.sbui-divider--right:before {
  --tw-border-opacity: 1;
  border-color: rgba(42, 42, 42, var(--tw-border-opacity));
}

.sbui-divider--light.sbui-divider > span {
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity));
}

.dark .sbui-divider--light.sbui-divider > span {
  --tw-text-opacity: 1;
  color: rgba(24, 24, 24, var(--tw-text-opacity));
}

.sbui-popover__content {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.dark .sbui-popover__content {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 31, 31, var(--tw-bg-opacity));
}

.sbui-popover__content {
  padding: 0;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(240, 242, 245, var(--tw-border-opacity));
}

.dark .sbui-popover__content {
  --tw-border-opacity: 1;
  border-color: rgba(42, 42, 42, var(--tw-border-opacity));
}

.sbui-popover__content {
  border-radius: 0.25rem;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  border-style: solid;
  border-width: 1px;
}

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  to {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  to {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0;
  }
}

.sbui-popover__content {
  -webkit-transform-origin: var(--radix-popover-menu-content-transform-origin);
  transform-origin: var(--radix-popover-menu-content-transform-origin);
}

.sbui-popover__content[data-state="open"] {
  -webkit-animation: fadeIn 50ms ease-out;
  animation: fadeIn 50ms ease-out;
}

.sbui-popover__content[data-state="closed"] {
  -webkit-animation: fadeOut 50ms ease-in;
  animation: fadeOut 50ms ease-in;
}

.sbui-popover__trigger {
  border-style: none;
  background-color: transparent;
  padding: 0;
}

.sbui-popover__trigger:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}

.sbui-popover__arrow {
  fill: currentColor;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.dark .sbui-popover__arrow {
  --tw-text-opacity: 1;
  color: rgba(31, 31, 31, var(--tw-text-opacity));
}

.sbui-popover__arrow {
  border-width: 1px 0 0;
  border-style: solid;
}

.sbui-popover__close {
  position: absolute;
  top: 0.25rem;
  right: 0;
  cursor: pointer;
  border-style: none;
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}

.dark .sbui-popover__close,
.sbui-popover__close:hover {
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}

.dark .sbui-popover__close:hover {
  --tw-text-opacity: 1;
  color: rgba(187, 187, 187, var(--tw-text-opacity));
}

.sbui-toggle {
  position: relative;
  display: inline-flex;
  height: 1.5rem;
  width: 2.75rem;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 9999px;
  border-width: 2px;
  border-color: transparent;
  padding: 0;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: 0.15s;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity));
}

.dark .sbui-toggle {
  --tw-bg-opacity: 1;
  background-color: rgba(102, 102, 102, var(--tw-bg-opacity));
}

.sbui-toggle:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.dark .sbui-toggle--active,
.sbui-toggle--active {
  --tw-bg-opacity: 1;
  background-color: rgba(101, 217, 165, var(--tw-bg-opacity));
}

.sbui-toggle__handle {
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x))
    translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: 0px;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width))
    var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, -webkit-transform, -webkit-filter,
    -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform,
    -webkit-filter, -webkit-backdrop-filter;
  transition-duration: 0.15s;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.sbui-toggle__handle--active {
  --tw-translate-x: 1.25rem;
}

.sbui-toggle:disabled {
  cursor: not-allowed;
  opacity: 0.75;
  pointer-events: none;
}

.sbui-toggle--tiny {
  height: 1rem;
  width: 1.75rem;
}

.sbui-toggle--tiny .sbui-toggle__handle {
  height: 0.75rem;
  width: 0.75rem;
}

.sbui-toggle--tiny .sbui-toggle__handle--active {
  --tw-translate-x: 0.75rem;
}

.sbui-toggle--medium,
.sbui-toggle--small {
  height: 1.5rem;
  width: 2.75rem;
}

.sbui-toggle--medium .sbui-toggle__handle,
.sbui-toggle--small .sbui-toggle__handle {
  height: 1.25rem;
  width: 1.25rem;
}

.sbui-toggle--medium .sbui-toggle__handle--active,
.sbui-toggle--small .sbui-toggle__handle--active {
  --tw-translate-x: 1.25rem;
}

.sbui-toggle--large,
.sbui-toggle--xlarge {
  height: 1.75rem;
  width: 3rem;
}

.sbui-toggle--large .sbui-toggle__handle,
.sbui-toggle--xlarge .sbui-toggle__handle {
  height: 1.5rem;
  width: 1.5rem;
}

.sbui-toggle--large .sbui-toggle__handle--active,
.sbui-toggle--xlarge .sbui-toggle__handle--active {
  --tw-translate-x: 1.25rem;
}

.light .remix-sidebar .menu-header {
  background-color: var(--bg-light-color);
}

.light .remix-sidebar .menu-list {
  background-color: var(--bg-light-color);
}

.light .remix-sidebar .menu-list ul li a,
.light .remix-sidebar .menu-list ul li .menu-item {
  color: #2a2a2a;
  border-color: #dadada;

  &.diver {
    border-color: #c5c5c5;
  }
}

.light .remix-sidebar .menu-header .btn-close {
  color: var(--text-light-color);
}

.light .banner-notice .item h5 {
  color: #ffbb00;
}

.light .banner-notice .item p {
  color: #ffff;
}

.light .notice .left span {
  color: var(--text-light-color);
}
